// NebulaGamingHub.js
import React from 'react';
import Web3 from 'web3';
import config from './config';
import { BsGithub, BsMedium } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { FaMedium } from "react-icons/fa";
import { RiMediumFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { BsFillBellFill } from 'react-icons/bs';
import { BsFillCalendarDateFill } from "react-icons/bs";
import { BsForwardFill } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsFillCursorFill } from "react-icons/bs";
import { BsFillCaretUpFill } from "react-icons/bs";


const NebulaGamingHub = () => {

    function addNetworkToMetaMask() {
        // Check if MetaMask is installed
        if (typeof window.ethereum !== 'undefined') {
          console.log('MetaMask is installed!');
        } else {
          alert('Please install MetaMask to use this feature.');
          return;
        }
      
        // Define the custom network configuration
        const customNetworkConfig = {
          chainId: `0x585eb4b1`, // Chain ID for Nebula Gaming Hub
          chainName: 'Nebula Gaming Hub', // Name of the network
          nativeCurrency: {
            name: 'sFUEL', // Name of the native currency
            symbol: 'sFUEL', // Symbol of the native currency
            decimals:  18 // Decimal places for the native currency
          },
          rpcUrls: [config.rpcUrl], // RPC URL(s) for the network
          blockExplorerUrls: ['https://green-giddy-denebola.explorer.mainnet.skalenodes.com'] // Block explorer URL(s) for the network
        };
      
        // Request MetaMask to add the custom network
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [customNetworkConfig],
        }).then((result) => {
          console.log('Successfully added network: ', result);
        }).catch((error) => {
          console.error('Failed to add network: ', error);
        });
      }

      
      

  return (
    <body id="top">

  <header className="header">

    <div className="header-top">
      <div className="container">
 
        <button onClick={addNetworkToMetaMask} className="btn skewBg">Add Network to MetaMask</button>

        <div className="social-wrapper">

          <p className="social-title">Follow us on :</p>

          <ul className="social-list">

            <li>
              <a href="https://twitter.com/NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link">
                < BsTwitterX />
              </a>
            </li>

            <li>
              <a href="https://github.com/NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link">
              < BsGithub />
              </a>
            </li>

            <li>
              <a href="https://ca.linkedin.com/company/nebula-gaming-hub" target="_blank" rel="noopener noreferrer" className="social-link">
                < BsLinkedin />
              </a>
            </li>

            <li>
              <a href="https://medium.com/@NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link">
                < FaMedium />
              </a>
            </li>

            <li>
              <a href="https://t.me/+ZZKnZjNyscNiYWIx" target="_blank" rel="noopener noreferrer" className="social-link">
                < FaTelegramPlane />
              </a>
            </li>

          </ul>

        </div>

      </div>
    </div>

    <div className="header-bottom" data-header>
      <div className="container">

        <a href="#" className="logo">NEBULA GAMING HUB</a>

        <nav className="navbar" data-navbar>
          <ul className="navbar-list">

            <li className="navbar-item">
              <a href="#home" className="navbar-link skewBg" data-nav-link>Home</a>
            </li>

            <li className="navbar-item">
              <a href="#live" className="navbar-link skewBg" data-nav-link>Gamer Tag</a>
            </li>

            <li className="navbar-item">
              <a href="#features" className="navbar-link skewBg" data-nav-link>Games</a>
            </li>

            <li className="navbar-item">
              <a href="#shop" className="navbar-link skewBg" data-nav-link>Bridge</a>
            </li>

            <li className="navbar-item">
              <a href="#blog" className="navbar-link skewBg" data-nav-link>News</a>
            </li>

            <li className="navbar-item">
              <a href="https://www.sfuelstation.com" target="_blank" rel="noopener noreferrer" className="navbar-link skewBg" data-nav-link>Get Sfuel</a>
            </li>

          </ul>
        </nav>

        <div className="header-actions">

        </div>

      </div>
    </div>

  </header>


  <main>
    <article>


      <section className="section hero" id="home" aria-label="home"
        style={{ backgroundImage: "url('./assets/images/headermainback.jpeg')" }}>
        <div className="container">

          <div className="hero-content">

            <p className="hero-subtitle">Built for Web3 Gaming</p>

            <h1 className="h1 hero-title">
              0 <span className="span">Gas Fees</span> Forever
            </h1>

            <p className="hero-subtitle">Powered by Skale Network</p>

          </div>

          <figure class="hero-banner img-holder" style={{width: '700px', height: '700px'}}>
            <img src="./assets/images/Nebula_Vector_Logo.png" width="700" height="700" alt="hero banner" class="w-100"/>
          </figure>

        </div>
      </section>

      <section
        style={{ backgroundImage: "url('./assets/images/brandbackground.png')" }}>
        <div className="container">

        <ul className="has-scrollbar">
        <li className="brand-item">
            </li>

            <li className="brand-item">
              <a href="https://skale.space" target="_blank" rel="noopener noreferrer">
                <img src="./assets/images/skale_logo_white_transparent.jpeg" width="200" height="180" loading="lazy" alt="Skale Logo"/>
              </a>
            </li>

            <li className="brand-item">
              <a href="https://meson.fi" target="_blank" rel="noopener noreferrer">
                <img src="./assets/images/meson.jpeg" width="200" height="180" loading="lazy" alt="Skale Logo"/>
              </a>
            </li>

            <li className="brand-item">
              <a href="https://ruby.exchange" target="_blank" rel="noopener noreferrer">
                <img src="./assets/images/ruby.jpeg" width="200" height="180" loading="lazy" alt="Meson Logo"/>
              </a>
            </li>

            <li className="brand-item">
              <a href="https://eidolongaming.dev" target="_blank" rel="noopener noreferrer">
                <img src="./assets/images/eidolon.jpeg" width="200" height="180" loading="lazy" alt="Eidolon Logo"/>
              </a>
            </li>
            <li className="brand-item">
            </li>

          </ul>


        </div>
      </section>

      <div className="section-wrapper">

        <section className="section latest-game" aria-label="latest game">
          <div className="container">

            <p className="section-subtitle">Nebula Gaming Hub</p>

            <h2 className="h2 section-title">
              Featured <span className="span">Games</span>
            </h2>

            <ul className="has-scrollbar">

              <li className="scrollbar-item">
                <div className="latest-game-card">

                  <figure className="card-banner img-holder" style={{ width: '400px', height: '470px' }}>
                    <img src="./assets/images/featured-game-4.jpg" width="400" height="470" loading="lazy"
                      alt="Hatchyverse" className="img-cover"/>
                  </figure>

                  <div className="card-content">

                    <a href="https://www.hatchyverse.com" target="_blank" rel="noopener noreferrer" className="card-badge skewBg">hatchyverse.com</a>

                    <h3 className="h3">
                      <a href="https://www.hatchyverse.com" target="_blank" rel="noopener noreferrer" className="card-title">Hatchyverse<span className="span"></span></a>
                    </h3>

                    <p className="card-price">
                      Category: <span className="span">Decentralized IP, Action, RPG</span>
                    </p>

                  </div>

                </div>
              </li>

              <li className="scrollbar-item">
                <div className="latest-game-card">

                  <figure className="card-banner img-holder" style={{ width: '400px', height: '470px' }}>
                    <img src="./assets/images/featured-gametwo.jpg" width="400" height="470" loading="lazy"
                      alt="Crypto Crusades" className="img-cover"/>
                  </figure>

                  <div className="card-content">

                    <a href="https://cryptocrusades.io" target="_blank" rel="noopener noreferrer" className="card-badge skewBg">cryptocrusades.io</a>

                    <h3 className="h3">
                      <a href="https://cryptocrusades.io" target="_blank" rel="noopener noreferrer" className="card-title">Crypto <span className="span">Crusades</span></a>
                    </h3>

                    <p className="card-price">
                      Category: <span className="span">Grand Strategy</span>
                    </p>

                  </div>

                </div>
              </li>

              <li className="scrollbar-item">
                <div className="latest-game-card">

                  <figure className="card-banner img-holder" style={{ width: '400px', height: '470px' }}>
                    <img src="./assets/images/featured-game-three.jpg" width="400" height="470" loading="lazy"
                      alt="Haven's Compass" className="img-cover"/>
                  </figure>

                  <div className="card-content">

                    <a href="https://havenscompass.com" target="_blank" rel="noopener noreferrer" className="card-badge skewBg">havenscompass.com</a>

                    <h3 className="h3">
                      <a href="https://havenscompass.com" target="_blank" rel="noopener noreferrer" className="card-title">Havens <span className="span">Compass</span></a>
                    </h3>

                    <p className="card-price">
                      Category: <span className="span">First person Shooter</span>
                    </p>

                  </div>

                </div>
              </li>

            </ul>

          </div>
        </section>

        <section className="section live-match" id="live" aria-label="live match">
          <div className="container">

            <h2 className="h2 section-title">
              Nebula <span className="span">Gamer Tag</span>
            </h2>
            <p className="section-text">
              Identify yourself accross different games within the Nebula Ecosystem<br/>Partake in DAO voting, earn XP and mine NEB token<br/>all
              with your Nebula Gamer Tag<br/> Coming Soon!
            </p>

            <figure className="live-match-banner img-holder" >

              <img src="./assets/images/nebula-gamer-tags-full.jpg" width="100%" height="600" loading="lazy"
                alt="Nebula Gamer Tags" className="img-cover"/>

            </figure>

           

          </div>
        </section>

      </div>


      <section className="section featured-game" id="features" aria-label="featured game" 
      style={{ backgroundImage: "url('./assets/images/section-wrapper-bg.png')" }}>
        <div className="container">

          <h2 className="h2 section-title">
            <span className="span">Nebula Games</span>
          </h2>

          <ul className="has-scrollbar">

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-1.jpg" width="300" height="420" loading="lazy"
                    alt="Havens Compass" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Havens <span className="span">Compass</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                    < BsFillBellFill />

                    <span className="span">PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://www.havenscompass.com/" target="_blank" rel="noopener noreferrer" className="card-title">
                      Havens <span className="span">Compass</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                    <span className="span">[First Person Shooter]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/cryptocrusadesfeature.jpg" width="300" height="420" loading="lazy"
                    alt="Crypto Crusades" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Crypto <span className="span">Crusades</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://cryptocrusades.io" target="_blank" rel="noopener noreferrer" className="card-title">
                      Crypto <span className="span">Crusades</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                    <span className="span">[Grand Strategy]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-motodex.jpg" width="300" height="420" loading="lazy"
                    alt="Moto Dex" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Moto <span className="span">Dex</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://motodex.openbisea.com" target="_blank" rel="noopener noreferrer"className="card-title">
                      Moto <span className="span">Dex</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                    <span className="span">[Racing]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-heroes.jpg" width="300" height="420" loading="lazy"
                    alt="Heroes Battle Arena" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Heroes <span className="span">Battle Arena</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://heroesbattlearena.online" target="_blank" rel="noopener noreferrer" className="card-title">
                      Heroes<span className="span">Battle Arena</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                    <span className="span">[Strategic RPG]</span>
                  </span>

                </div>

              </div>
            </li>   

          </ul>

          <br/>

          <ul className="has-scrollbar">

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-voxelverse.jpg" width="300" height="420" loading="lazy"
                    alt="The Voxelverse" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      The <span className="span">VoxelVerse</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://www.voxelverse.ca" target="_blank" rel="noopener noreferrer" className="card-title">
                      The <span className="span">VoxelVerse</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                    <span className="span">[Metaverse, Adventure]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-4.jpg" width="300" height="420" loading="lazy"
                    alt="Hatchy Verse" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Hatchy <span className="span">Verse</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile & PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://www.hatchyverse.com" target="_blank" rel="noopener noreferrer"className="card-title">
                      Hatchy <span className="span">Verse</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC & Mobile</span>
                    <span className="span">[Action, RPG]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-3.jpg" width="300" height="420" loading="lazy"
                    alt="5tars" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      5tars <span className="span"></span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://5tars.io/" target="_blank" rel="noopener noreferrer" className="card-title">
                      5tars <span className="span"></span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-bithotel.jpg" width="300" height="420" loading="lazy"
                    alt="Bit Hotel" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Bit <span className="span">Hotel</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile & PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://bithotel.io/" target="_blank" rel="noopener noreferrer" className="card-title">
                      Bit<span className="span">Hotel</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile & PC</span>
                  </span>

                </div>

              </div>
            </li>   

          </ul>
          
          <br/>

          <ul className="has-scrollbar">

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-yomi.jpg" width="300" height="420" loading="lazy"
                    alt="Yomi Block Puzzle" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Yomi <span className="span">Block Puzzle</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">MOBILE</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://yomigames.gg/tech/yomi-block-puzzle/" target="_blank" rel="noopener noreferrer" className="card-title">
                      Yomi <span className="span">Block Puzzle</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                    <span className="span">[Puzzle]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/cricket.jpg" width="300" height="420" loading="lazy"
                    alt="Gamifly" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Gamifly <span className="span"></span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://gamifly.co" target="_blank" rel="noopener noreferrer" className="card-title">
                      Gamifly <span className="span"></span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">Mobile</span>
                    <span className="span">[Sports]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/sog.jpg" width="300" height="420" loading="lazy"
                    alt="Spells of Genesis" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Spells of Genesis <span className="span"></span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC & Mobile</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://spellsofgenesis.com" target="_blank" rel="noopener noreferrer" className="card-title">
                      Spells of  <span className="span">Genesis</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC & Mobile</span>
                    <span className="span">[Trading Card Strategy]</span>
                  </span>

                </div>

              </div>
            </li>

            <li className="scrollbar-item">
              <div className="featured-game-card">

                <figure className="card-banner img-holder" style={{ width: '300px', height: '420px', padding: '2px' }}>
                  <img src="./assets/images/featured-game-ballers.jpg" width="300" height="420" loading="lazy"
                    alt="Ballers City" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <h3 className="h3">
                    <a href="#" className="card-title" tabindex="-1">
                      Ballers <span className="span">City</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                  </span>

                </div>

                <div className="card-content-overlay">

                  <img src="./assets/images/featured-game-icon.png" width="36" height="61" loading="lazy" alt=""
                    className="card-icon"/>

                  <h3 className="h3">
                    <a href="https://ballers.fun" target="_blank" rel="noopener noreferrer" className="card-title">
                      Ballers<span className="span">City</span>
                    </a>
                  </h3>

                  <span className="card-meta">
                  < BsFillBellFill />

                    <span className="span">PC</span>
                    <span className="span">[Casual]</span>
                  </span>

                </div>

              </div>
            </li>   

          </ul>

        </div>
      </section>

      <section className="section shop" id="shop" aria-label="shop"
        style={{ backgroundImage: "url('./assets/images/section-wrapper-bg.png')" }}>
        <div className="container">

          <h2 className="h2 section-title">
            BRIDGE <span className="span">TOKENS</span>
          </h2>

          <p className="section-text">
            Bridge SKL, USDC, USDT, WBTC, ETH and associated game erc20 tokens between Skale Chains, to Ethereum or other supported layer 2 Chains
          </p>

          <ul className="has-scrollbar">
          <li>
              
              </li>
              <li>
              
              </li>
              <li>
              
              </li>

            <li>
              <div className="shop-card">

                <figure className="card-banner img-holder" style={{ width: '471px', height: '306px' }}>
                  <img src="./assets/images/desktopmetaport.png" width="471" height="306" loading="lazy"
                    alt="Skale Portal" className="img-cover"/>
                </figure>

                <div className="card-content">

                <a href="https://portal.skale.space/bridge" target="_blank" rel="noopener noreferrer" className="card-badge skewBg">METAPORT</a>

                  <h3 className="h3">
                    <a href="https://portal.skale.space/bridge" target="_blank" rel="noopener noreferrer" className="card-title">Skale Ecosystem Bridge</a>
                  </h3>

                  <div className="card-wrapper">
                    <p className="card-price">Ethereum or Skale Chains</p>
                  </div>

                </div>

              </div>
            </li>
            <li>
              
              </li>

              <li>
              
              </li>

            <li>
              <div className="shop-card">

                <figure className="card-banner img-holder" style={{ width: '471px', height: '306px' }}>
                  <img src="./assets/images/desktopmeson.png" width="471" height="306" loading="lazy"
                    alt="Meson.fi" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <a href="https://meson.fi" target="_blank" rel="noopener noreferrer" className="card-badge skewBg">MESON</a>

                  <h3 className="h3">
                    <a href="https://meson.fi" target="_blank" rel="noopener noreferrer" className="card-title">Cross-Chain for Stablecoins</a>
                  </h3>

                  <div className="card-wrapper">
                    <p className="card-price">19 Chains Supported</p>
                  </div>

                </div>

              </div>
            </li>



          </ul>


        </div>
      </section>

      <section className="section blog" id="blog" aria-label="blog">
        <div className="container">

          <h2 className="h2 section-title">
            Latest News & <span className="span">Articles</span>
          </h2>

          <p className="section-text">
            Latest from the Nebula Gaming Hub and Skaleverse Ecosystem
          </p>

          <ul className="blog-list">

          <li>
              <div className="blog-card">

                <figure className="card-banner img-holder" style={{ width: '400px', height: '290px' }}>
                  <img src="./assets/images/Yomi-Featured.webp" width="400" height="290" loading="lazy"
                    alt="Skale Monthly Users" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                    <BsPersonFill />

                      <a href="#" className="item-text">NGH</a>
                    </li>

                    <li className="card-meta-item">
                    <BsFillCalendarDateFill />

                      <time datetime="2024-02-06" className="item-text">February 18, 2024</time>
                    </li>

                  </ul>

                  <h3>
                    <a href="https://twitter.com/NebulaGamingHub/status/1757853157613318542" target="_blank" rel="noopener noreferrer" className="card-title">Yomi Block Puzzle Spaces</a>
                  </h3>

                  <p className="card-text">
                    Nebula Gaming Hub Spaces featuring Yomi Block Puzzle
                  </p>

                  <a href="https://twitter.com/NebulaGamingHub/status/1757853157613318542" target="_blank" rel="noopener noreferrer" className="card-link">
                    <span className="span">Tune In</span>

                    <BsForwardFill />
                  </a>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner img-holder" style={{ width: '400px', height: '290px' }}>
                  <img src="./assets/images/Messari Nebula.jpg" width="400" height="290" loading="lazy"
                    alt="Nebula avg daily new addresses" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                    <BsPersonFill />

                      <a href="https://messari.io" target="_blank" rel="noopener noreferrer" className="item-text">Messari.io</a>
                    </li>

                    <li className="card-meta-item">
                    <BsFillCalendarDateFill />

                      <time datetime="2024-02-09" className="item-text">February 9, 2024</time>
                    </li>

                  </ul>

                  <h3>
                    <a href="https://messari.io/report/state-of-skale-q4-2023" target="_blank" rel="noopener noreferrer" className="card-title">Messari State of Skale Q4 2023</a>
                  </h3>

                  <p className="card-text">
                    Nebula average daily new addresses rise.
                  </p>

                  <a href="https://messari.io/report/state-of-skale-q4-2023" target="_blank" className="card-link">
                    <span className="span">Read More</span>
                    <BsForwardFill />
                  </a>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner img-holder" style={{ width: '400px', height: '290px' }}>
                  <img src="./assets/images/cryptocrusades.jpg" width="400" height="290" loading="lazy" alt="Crypto Crusades"
                    className="img-cover"/>
                </figure>

                <div className="card-content">

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                    <BsPersonFill />

                      <a href="#" className="item-text">NGH</a>
                    </li>

                    <li className="card-meta-item">
                    <BsFillCalendarDateFill />

                      <time datetime="2024-01-21" className="item-text">January 21, 2024</time>
                    </li>

                  </ul>

                  <h3>
                    <a href="https://twitter.com/NebulaGamingHub/status/1745994198619217993" target="_blank" rel="noopener noreferrer" className="card-title">Crypto Crusades Spaces</a>
                  </h3>

                  <p className="card-text">
                    Nebula Gaming Hub Spaces featuring Crypto Crusades.
                  </p>

                  <a href="https://twitter.com/NebulaGamingHub/status/1745994198619217993" target="_blank" rel="noopener noreferrer" className="card-link">
                    <span className="span">Tune In</span>

                    <BsForwardFill />
                  </a>

                </div>

              </div>
            </li>

            <li></li>
            <li>
              <div className="blog-card">

                <figure className="card-banner img-holder" style={{ width: '400px', height: '290px' }}>
                  <img src="./assets/images/skalemonthly.png" width="400" height="290" loading="lazy"
                    alt="Skale Monthly Users" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                    <BsPersonFill />

                      <a href="#" className="item-text">Skale</a>
                    </li>

                    <li className="card-meta-item">
                    <BsFillCalendarDateFill />

                      <time datetime="2024-02-06" className="item-text">February 6, 2024</time>
                    </li>

                  </ul>

                  <h3>
                    <a href="https://skale.space/blog/skale-ecosystem-recap---january-2024" target="_blank" rel="noopener noreferrer" className="card-title">Skale Monthly Users ATH</a>
                  </h3>

                  <p className="card-text">
                    Skale Ecosystem Recap January 2024
                  </p>

                  <a href="https://skale.space/blog/skale-ecosystem-recap---january-2024" target="_blank" rel="noopener noreferrer" className="card-link">
                    <span className="span">Read More</span>

                    <BsForwardFill />
                  </a>

                </div>

              </div>
            </li>

          </ul>

        </div>
      </section>


      

    </article>
  </main>


  <footer className="footer">

    <div className="footer-top">
      <div className="container">

        <div className="footer-brand">
      <br/>
      <br/>
          <a href="#" className="logo">Nebula Gaming Hub</a>

          <p className="footer-text">
            Zero Fee, L2 EVM Blockchain for Web3 Gaming. Powered by SKALE
          </p>

          <ul className="contact-list">

            <li className="contact-item">
              <div className="contact-icon">
              <BsFillEnvelopeFill />
              </div>

              <a href="mailto:info@exemple.com" className="item-text">Email : info@nebulachain.io</a>
            </li>

          </ul>

        </div>

       

        <ul className="footer-list">
        <br/>
        <br/>
          <li>
            <p className="footer-list-title">Need Help?</p>
          </li>

          <li>
            <a href="https://www.sfuelstation.com" target="_blank" rel="noopener noreferrer" className="footer-link">Get Sfuel</a>
          </li>

          <li>
            <a href="https://green-giddy-denebola.explorer.mainnet.skalenodes.com/" target="_blank" rel="noopener noreferrer" className="footer-link">Nebula Explorer</a>
          </li>

          <li>
            <a href="https://portal.skale.space/chains/nebula" target="_blank" rel="noopener noreferrer" className="footer-link">Chain Information</a>
          </li>

        </ul>

        <div className="footer-wrapper">

          <div className="social-wrapper">
          <br/>
          <br/>
            <p className="footer-list-title">Follow Us</p>

            <ul className="social-list">

              <li>
                <a href="https://twitter.com/NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link" style={{ backgroundColor: "#55acee" }}>
                <BsTwitterX />
                </a>
              </li>

              <li>
                <a href="https://github.com/NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link" style={{ backgroundColor: "#8c7f7f" }}>
                <BsGithub />
                </a>
              </li>

              <li>
                <a href="https://ca.linkedin.com/company/nebula-gaming-hub" target="_blank" rel="noopener noreferrer" className="social-link" style={{ backgroundColor: "#1565c0" }}>
                  <BsLinkedin />
                </a>
              </li>

              <li>
              <a href="https://medium.com/@NebulaGamingHub" target="_blank" rel="noopener noreferrer" className="social-link" style={{ backgroundColor: "#606060" }}>
                < RiMediumFill />
              </a>
            </li>

            <li>
              <a href="https://t.me/+ZZKnZjNyscNiYWIx" target="_blank" rel="noopener noreferrer" className="social-link" style={{ backgroundColor: "#03A9F4" }}>
                < FaTelegramPlane />
              </a>
            </li>

            </ul>

          </div>

          

        </div>

      </div>
    </div>

    <div className="footer-bottom">
      <div className="container">

        <p className="copyright">
          &copy; 2023 - 2024 Nebula Gaming Hub.
        </p>



      </div>
    </div>

  </footer>



  <a href="#top" className="back-top-btn" aria-label="back to top" data-back-top-btn>
  <BsFillCaretUpFill />
  </a>

  <script src="./assets/js/script.js" defer></script>



</body>
);
};

export default NebulaGamingHub;
